<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs1>
        <v-subheader><v-icon>{{ getIcon }}</v-icon></v-subheader>
      </v-flex>
      <v-flex xs2>
        <v-subheader>{{ getFriendly }} {{ document.status }}</v-subheader>
      </v-flex>
      <v-flex xs3>
        <v-subheader v-if="showEnrolled">
          <v-select
            :items="Object.keys(document.filesByDate)"
            item-value="guid"
            item-text="created"
            v-model="currentTimestamp"
            @input="changeDocument"
          />
      </v-subheader>
      </v-flex>
    </v-layout>
    <v-layout v-if="showEnrolled" row>
      <v-flex xs1 />
      <v-flex xs2><v-subheader>Available Files</v-subheader></v-flex>
      <v-flex xs8 class="pt-3" v-if="hasFiles">
        <v-layout row>
          <v-flex xs3
          v-for="file in document.filesByDate[this.currentTimestamp]"
          :key="file.guid"
          >
            <img
              :src="generateUrl(file.guid)"
              @click="open(file.guid)"
              class="img-capture"
            />

            <BiometricImageDialog
              :open="openDialogGuid[file.guid]"
              :document="file"
              :authToken="accessToken"
              @closed="closed(file.guid)"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs8 class="pt-3 pl-3" v-else>
        <v-layout row>
          <v-flex xs3>No images available.</v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<style scoped>
  .img-capture {
    cursor: pointer;
    max-height: 100px;
    padding-right: 2px;
  }
</style>

<script>
import Vue from 'vue';
import ues from '@/services/UserEnrollmentService';
import BiometricMap from '@/utils/BiometricMap';

export default {
  name: 'BaseImageCapture',
  props: {
    document: Object,
    accessToken: String,
  },
  computed: {
    getIcon() {
      const { icon } = BiometricMap.get(this.document.captureType);
      return icon;
    },
    getFriendly() {
      const { friendly } = BiometricMap.get(this.document.captureType);
      return friendly;
    },
    hasFiles() {
      return this.document.filesByDate[this.currentTimestamp]
        && this.document.filesByDate[this.currentTimestamp].length > 0;
    },
    showEnrolled() {
      return this.document.status === 'Enrolled';
    },
  },
  data: () => ({
    openDialogGuid: [],
    displayFiles: [],
    currentTimestamp: '',
  }),
  components: {
    BiometricImageDialog: () => import('@/components/dialogs/BiometricImageDialog'),
  },
  methods: {
    generateUrl(guid) {
      if (this.accessToken) {
        return ues.getGuidSrc(guid, this.accessToken);
      }
      return '';
    },
    closed(guid) {
      Vue.set(this.openDialogGuid, guid, false);
    },
    open(guid) {
      Vue.set(this.openDialogGuid, guid, true);
    },
    changeDocument(val) {
      this.displayFiles = this.document.filesByDate[val];
    },
  },
  mounted() {
    if (this.document.hasFiles) {
      const displayDate = Object.keys(this.document.filesByDate)[0];
      this.displayFiles = this.document.filesByDate[displayDate];
      this.currentTimestamp = displayDate;
    }
  },
};
</script>
